import React, { useState, useEffect, useMemo } from "react";
import { Label, FormGroup} from "reactstrap";
import { Auth } from "aws-amplify";
import MaterialReactTable from "material-react-table";
import Select from "react-select";
import getMonitorReport from "../../utils/montoring";
import html2pdf from "html2pdf.js";
import { DualAxesChart } from "./DualAxesChart";
import { Box } from "@mui/material";

export const Monitoring = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const [year, setYear] = useState(currentYear.toString());
  let [policies, setPolicies] = useState([]);
  let [defaultValues, setDefaultValues] = useState([]);
  const [ndviTable, setNDVITable] = useState('');
  const [seasonality, setSeasonality] = useState([])
  const [stateName, setStateName] = useState("AlQadarif");
  const states = ["AlQadarif",'Kassala'];
  const [wardName, setWardName] = useState("All");
  const [wards, setWards] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, // Number of rows per page
  });



  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

        fetch("/api/climate/getWardNames?state_name=" + stateName , {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setWards(data);
          });

        }
        genToken()
       
      },[stateName])

    

  useEffect(() => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();


      if (year !== null) {
        fetch(
          "/api/monitoring/monitoringTable?state_name=" +
          stateName +
          "&ward_name=" +
          wardName + 
          "&year=" +
          year,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setPolicies(data);
            //console.log(data)
          });
        fetch(
          "/api/monitoring/defaultParameterValues?state_name=" +
          stateName +
          "&year=" +
          year,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => setDefaultValues(data));

        fetch(
          "/api/monitoring/monitoringReportNDVITable?state_name=" +
          stateName +
          "&wardName=" +
          wardName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setNDVITable(data.result);
          });

        fetch(
          "/api/monitoring/monitoringReportGraph?state_name=" +
          stateName +
          "&wardName=" +
          wardName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setSeasonality(data);
          });

      }

      if (!didCancel) {
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  }, [wardName, year]);

    const columns = useMemo(() => {
    const iuColumn={
      header: "Locality Name",
      accessorKey: "ward",
      size: 70,
    };

    const otherColumns = [
    {
      header: "Sum Insured",
      accessorKey: "sum_insured",
      Cell: ({ cell }) =>
        cell
          .getValue()
          .toLocaleString("en-US", { style: "currency", currency: "USD" }),
      size: 50,
    },
    {
      header: "Trigger",
      accessorKey: "trigger",
      size: 50,
    },
    {
      header: "Exit",
      accessorKey: "exit",
      size: 80,
    },
    {
      header: "NDVI (normalized)",
      accessorKey: "ndvi",
      size: 80,
    },
    {
      header: "Payout(%)",
      accessorKey: "payout",
      size: 50,
    },
    {
      header: " Policy Status",
      accessorKey: "policy_status",
      Header: (
        <>
          <div id="status">Policy Status</div>
         {/* <Tooltip isOpen={policyStatus} target="status" toggle={toggle}>
            Grey: Inactive, Red: Active
          </Tooltip>*/}
        </>
      ),
      Cell: ({ cell }) => {
        return (
          <span
            className="colored-circle"
            style={{
              backgroundColor:
                cell.getValue() === "Inactive" ? "#808080" : "#00cc00",
              borderColor:
                cell.getValue() === "Inactive" ? "#808080" : "#00cc00",
              display: "inline-block",
              marginLeft: "5px",
              marginRight: "5px",
              marginBottom: "-2px",
              borderRadius: "100%",
              borderStyle: "solid",
              borderWidth: "0.5px",
              height: "15px",
              width: "15px",
            }}
          />
        );
      },
      size: 50,
    },
    {
      header: " Trigger Status",
      accessorKey: "trigger_status",
      Header: (
        <>
          <div id="trigger">Trigger Status</div>
         {/* <Tooltip isOpen={trigger} target="trigger" toggle={toggle2}>
            Grey: No, Red: Yes
          </Tooltip>*/}
        </>
      ),
      Cell: ({ cell }) => {
        return (
          <span
            className="colored-circle"
            style={{
              backgroundColor:
                cell.getValue() === "No" ? "#808080" : "#ff0000",
              borderColor: cell.getValue() === "No" ? "#808080" : "#ff0000",
              display: "inline-block",
              marginLeft: "5px",
              marginRight: "5px",
              marginBottom: "-2px",
              borderRadius: "100%",
              borderStyle: "solid",
              borderWidth: "0.5px",
              height: "15px",
              width: "15px",
            }}
          />
        );
      },
      size: 50,
    }]
    return [iuColumn,...otherColumns];
  }
    , [policies]);

  

  let years = [{ value: year, label: year }];

  const downloadPdfDocument = () => {
    const input = getMonitorReport(
      wardName,
      defaultValues["trigger_level"],
      defaultValues["trigger_value"],
      defaultValues["exit_level"],
      defaultValues["exit_value"],
      defaultValues["maximum_payout"],
      defaultValues["premium_rate"],
      defaultValues["start_date"],
      defaultValues["end_date"],
      ndviTable,
      policies[0]["payout"],
      defaultValues["sum_insured_dollars"],
    );

    let div = document.createElement("div");
    div.innerHTML = input;
    let element = document.getElementById("dualchart");
    let clone = element ? element.cloneNode(true) : null;
    clone.style.display = 'block';
    clone.style.width = "1200px";
    let targetElement = div.querySelector(`#dualaxeschartposition`);
    targetElement.appendChild(clone);
    document.body.appendChild(div);
    let opt = {
      margin: 1,
      filename: wardName + "_Monitoring.pdf",
      html2canvas: { scale: 2 },
    };
    html2pdf().set(opt).from(div).save();
  }

  let stateObject = [];
  let wardObject = [{value: "All", label: "All"}];

  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }

  for (let j in wards) {
    let items = {};
    items["label"] = wards[j];
    items["value"] = wards[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    wardObject.push(json_obj);
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };

  const [columnOrder, setColumnOrder] = useState([]);

  useEffect(() => {
    if (columns && columns.length > 0) {
      setColumnOrder(["iuColumn", "contract_name", "sum_insured", "trigger", "exit", "ndvi", "payout", "policy_status", "trigger_status"]);
    }
  }, [columns]);

  const paginatedPolicies = useMemo(() => {
    const start = pagination.pageIndex * pagination.pageSize;
    const end = start + pagination.pageSize;
    return policies.slice(start, end);
  }, [policies, pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      <div
        className="row justify-content-center"
        style={{
          backgroundColor: "#fff",
          minHeight: "calc(100vh - 90px)",
          overflowY: 'auto',
          maxHeight: '100vh',

        }}
      >
        <div className="row justify-content-center mt-5">
          <div className="col-md-11">
          <div className="row justify-content-between mb-4">
              <div className="col-md-7 mt-4" style={{ textAlign: "left" }}>
                <h5>IU trigger monitoring</h5>
              </div>
              <div className="col-md-5" style={{ fontSize: "10px" }}>
                <div className="row">
                  <div className="col-md-2">
                    <Label>State</Label>
                    <FormGroup>
                      <Select
                        options={stateObject}
                        value={{
                          value: stateName,
                          label: stateName,
                        }}
                        onChange={(obj) => {
                          setStateName(obj["value"]);
                          setWardName(null)
                          setPolicies([]);
                          setWards([])
                        }}
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </div>
                 
                  <div className="col-md-2">
                    <Label>Locality</Label>
                    <FormGroup>
                      <Select
                        options={wardObject}
                        value={{
                          value: wardName,
                          label: wardName,
                        }}
                        onChange={(obj) => {
                          setWardName(obj["value"]);
                          setPolicies([]);
                        }}
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </div>
                 

                  <div className="col-md-2">
                    <Label>Year</Label>
                    <FormGroup>
                      <Select
                        options={years}
                        value={{
                          value: year,
                          label: year,
                        }}
                        onChange={(obj) => setYear(obj["value"])}
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            {policies ? (
              <>
              <Box sx={{maxHeight: "60vh", overflow: "auto"}}>
                <MaterialReactTable
                  columns={columns}
                  data={paginatedPolicies} // Use paginated data
                  enablePagination
                  //onPaginationChange={setPagination}
                  //state={{ pagination }} // Pass the pagination state
                  enableRowSelection={false}
                  enableColumnOrdering={false}
                  enableTopToolbar={false}
                  initialState={{
                    density: "compact",
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 20, 50, 100], // Customize page size options
                    labelRowsPerPage: "Rows per page:",
                  }}
                  getRowId={(row) => row.id} // Important for proper pagination
                />
                </Box>
                <DualAxesChart ward={wardName} data={seasonality} />
              </>
            ) : (
              "No Data Available"
            )}
          </div>
        </div>
      </div>
    </>
  );
};
